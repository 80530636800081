import { InputAdornment, makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import Counter from '../Counter';
import { useContextualCanWrite } from '../../../abilities/hooks';
import ContextualCan from '../../../abilities/components/ContextualCan';
import { READ } from '../../../abilities/actions';

const useStyles = makeStyles({
    input: {
        margin: 0,
    },
});

const BaseTextInput = ({
    value,
    name,
    label,
    onChange,
    type,
    error,
    fullWidth,
    multiline,
    rows,
    rowsMax,
    maxLength,
    maxLengthHidden,
    disabled,
    variant,
    money,
    endAdornment,
    InputProps,
    helperText,
    autocomplete,
    ...other
}) => {
    const classes = useStyles();
    const canWrite = useContextualCanWrite(name);

    const handleChange = useCallback(
        (event) => {
            const newValue = event.target.value;
            const prepped = maxLength ? newValue.substring(0, maxLength) : newValue;

            if (onChange) {
                onChange(name, prepped);
            }

            if (InputProps && InputProps.onChange) {
                InputProps.onChange(event);
            }
        },
        [name, onChange, maxLength, InputProps]
    );

    const finalInputProps = useMemo(() => {
        const props = {
            ...InputProps,
        };
        if (endAdornment) {
            props.endAdornment = endAdornment;
        } else if (money) {
            props.endAdornment = <InputAdornment position="start">€</InputAdornment>;
        } else if (maxLength && !maxLengthHidden) {
            props.endAdornment = <Counter maxLength={maxLength} value={value} fixed={multiline} />;
        }
        return props;
    }, [maxLength, maxLengthHidden, money, multiline, value, InputProps, endAdornment]);

    return (
        <ContextualCan I={READ} field={name}>
            <TextField
                margin="dense"
                variant={variant}
                error={!!error}
                helperText={error || helperText}
                className={classes.input}
                data-cy={`input-${name}`}
                {...other}
                disabled={disabled || !canWrite}
                value={value}
                label={label}
                type={type}
                onChange={handleChange}
                fullWidth={fullWidth}
                multiline={multiline}
                rows={rows}
                rowsMax={rowsMax}
                InputProps={finalInputProps}
                autoComplete={autocomplete}
            />
        </ContextualCan>
    );
};

BaseTextInput.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.string,
    type: PropTypes.string,
    fullWidth: PropTypes.bool,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
    rowsMax: PropTypes.number,
    maxLength: PropTypes.number,
    maxLengthHidden: PropTypes.bool,
    disabled: PropTypes.bool,
    variant: PropTypes.string,
    money: PropTypes.bool,
    InputProps: PropTypes.shape({}),
    endAdornment: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    helperText: PropTypes.string,
    autocomplete: PropTypes.string,
};

BaseTextInput.defaultProps = {
    value: undefined,
    label: null,
    error: null,
    onChange: null,
    type: 'text',
    variant: 'outlined',
    fullWidth: false,
    multiline: false,
    rows: null,
    rowsMax: null,
    maxLength: null,
    maxLengthHidden: false,
    disabled: false,
    money: false,
    InputProps: null,
    endAdornment: null,
    helperText: null,
    autocomplete: 'on',
};

export default memo(BaseTextInput);
