import { loginError, loginSuccess, logoutSuccess } from './authSlice';
import logger from '../../logger';
import { resetApp } from '../app/actions';

export const logout =
    (data = null) =>
    (dispatch, getState, api) =>
        api.logout(data).then(() => {
            dispatch(resetApp());
            dispatch(logoutSuccess());
        });


export const requestOTP = (data) => (dispatch, getState, api) =>
    api.requestOTP(data)
        .then(() => {
            return { success: true };
        })
        .catch((error) => {
            logger.error("error =>",error);
            dispatch(loginError(error));
            return { success: false };
        });

/**
 * runs the default authentication without OTP
 * @param {*} data 
 * @returns 
 */
export const login = (data) => (dispatch, getState, api) =>
    dispatch(() =>
        api
            .authenticate(data)
            .then(({ user, userV3 }) => dispatch(loginSuccess({ user, userV3 })))
            .catch((error) => {
                logger.error(error);
                dispatch(loginError(error));
                return error;
            })
    );

/**
 * runs the extended authentication with OTP
 * @param {*} data 
 * @returns 
 */
export const loginWithOtp = (data) => (dispatch, getState, api) =>
    dispatch(() =>
        api
            .authenticate(data)
            .then(({ user, userV3 }) => dispatch(loginSuccess({ user, userV3 })))
            .catch((error) => {
                logger.error(error);
                dispatch(loginError(error));
                return error;
            })
    );