import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { getFormFieldError } from '../../../helpers/errors';
import { SaveErrorPropType } from '../../../proptypes/errors';
import { useFieldTurbo } from '../hooks';
import BaseTextInput from './base/BaseTextInput';
import { withPrefix } from '../utils';

const TextInput = ({
    name,
    prefix,
    label,
    onChange,
    type,
    errors,
    fullWidth,
    multiline,
    rows,
    rowsMax,
    maxLength,
    maxLengthHidden,
    disabled,
    money,
    endAdornment,
    helperText,
    noTurbo,
    autocomplete,
    ...other
}) => {
    const prefixedName = withPrefix(name, prefix);
    const [field, meta, helpers] = useFieldTurbo(prefixedName, noTurbo);
    const apiError = getFormFieldError(prefixedName, errors);

    const handleChange = useMemo(
        () => (_name, newValue) => {
            helpers.setValue(newValue);
            if (onChange) {
                onChange(_name, newValue);
            }
        },
        [helpers, onChange]
    );

    const handleBlur = useCallback(() => {
        helpers.setTouched(prefixedName, true);
    }, [helpers, prefixedName]);

    const handleError = useMemo(
        () => (error) => {
            helpers.setError(error);
        },
        [helpers]
    );

    return (
        <BaseTextInput
            {...field}
            onBlur={handleBlur}
            name={prefixedName}
            label={label}
            type={type}
            onChange={handleChange}
            onError={handleError}
            fullWidth={fullWidth}
            error={apiError || (meta.touched && meta.error) || null}
            multiline={multiline}
            rows={rows}
            rowsMax={rowsMax}
            maxLength={maxLength}
            maxLengthHidden={maxLengthHidden}
            disabled={disabled}
            money={money}
            endAdornment={endAdornment}
            helperText={helperText}
            autocomplete={autocomplete}
            {...other}
        />
    );
};

TextInput.propTypes = {
    name: PropTypes.string.isRequired,
    prefix: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    errors: SaveErrorPropType,
    type: PropTypes.string,
    fullWidth: PropTypes.bool,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
    rowsMax: PropTypes.number,
    maxLength: PropTypes.number,
    maxLengthHidden: PropTypes.bool,
    disabled: PropTypes.bool,
    money: PropTypes.bool,
    endAdornment: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    helperText: PropTypes.string,
    noTurbo: PropTypes.bool,
    autocomplete: PropTypes.string,
};

TextInput.defaultProps = {
    prefix: null,
    label: null,
    errors: null,
    onChange: null,
    type: 'text',
    fullWidth: false,
    multiline: false,
    rows: null,
    rowsMax: null,
    maxLength: null,
    maxLengthHidden: true,
    disabled: false,
    money: false,
    endAdornment: null,
    helperText: null,
    noTurbo: false,
    autocomplete: 'on',
};

export default memo(TextInput);
